.section-prizes {
    .section-prizes__title {
        font-size: 2.75rem;
        padding-bottom: 2rem;
    }

    .prize-item--small-prize {
        .prize-item__inner {
            padding: 1rem;

            @media(max-width: 767px) {
                padding: 3rem 1rem;
            }

            @media(max-width: 640px) {
                padding: 2rem;
            }

            @media(max-width: 540px) {
                padding: 1rem;
            }

            h4 {
                @media(max-width: 767px) {
                    font-size: 4.25rem !important;
                }

                @media(max-width: 640px) {
                    font-size: 2.25rem !important;
                }

                @media(max-width: 540px) {
                    font-size: 1.5rem !important;
                }
            }
            
        }

        .prize-item__description {
            font-size: 1.25rem;

            @media(max-width: 767px) {
                font-size: 2rem;
            }

            @media(max-width: 640px) {
                font-size: 1.5rem;
            }

            @media(max-width: 540px) {
                font-size: 1rem;
            }
        }

        img {
            max-width: 97px;
            padding-top: 1.25rem;
            padding-bottom: 1.25rem;

            @media(max-width: 1200px) {
                max-width: 80px;
            }
        }

        &:nth-child(1),
        &:nth-child(2) {
            @media(max-width: 767px) {
                padding-bottom: 2rem;
            }
        }

        &:nth-child(3) {
            @media(max-width: 370px) {
                padding-bottom: 2rem;
            }
        }
    }
}