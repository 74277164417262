.section-benefits {
    .benefits__title {
        font-size: 2.75rem;
        padding-bottom: 3.25rem;

        @media(max-width: 560px) {
            font-size: 1.75rem;
        }
    }

    img {
        max-width: 110px;
        height: 100px;
        padding-bottom: 2.25rem;

        @media(max-width: 560px) {
            max-width: 100px;
            height: 90px;
        }
    }

    .benefit__inner {
        @media (max-width: 768px) {
            padding-bottom: 7rem;
        }
        @media (max-width: 640px) {
            padding-bottom: 4rem;
        }
    }

    .benefit__name {
        font-size: 1.5rem;
        padding-bottom: 1rem;

        @media(max-width: 768px) {
            font-size: 2rem;
        }

        @media(max-width: 560px) {
            font-size: 1.5rem;
        }
    }

    .benefit__description {
        padding-right: 4rem;

        @media(max-width: 992px) {
            padding-right: 2rem;
        }

        @media(max-width: 768px) {
            padding-right: 0;
            font-size: 1.25rem;
        }

        @media(max-width: 768px) {
            font-size: 1rem;
        }
    }

    .col-md-3:nth-child(2) .benefit__inner,
    .col-md-3:nth-child(4) .benefit__inner {
        @media(max-width: 768px) {
            margin-left: 2rem;
        }
        @media(max-width: 640px) {
            margin-left: 1rem;
        }
        @media(max-width: 370px) {
            margin-left: 0;
        }
    }

    .col-md-3:nth-child(1) .benefit__inner,
    .col-md-3:nth-child(3) .benefit__inner {
        @media(max-width: 768px) {
            margin-right: 2rem;
        }
        @media(max-width: 640px) {
            margin-right: 1rem;
        }
        @media(max-width: 370px) {
            margin-right: 0;
        }
    }
}