.section-campaign-over {
    background-color: #000000;
    align-items: center;
    overflow: auto;
    display: none;
    transition: all 0.5s ease-in-out;

    .campaign-over__title {
        font-size: 3.5rem;
        
        @media(max-width: 560px) {
            font-size: 2.5rem;
        }
    }

    .campaign-over__info {
        font-size: 1.25rem;
        width: 80%;

        @media (min-width: 768px) {
            width: 100%;
        }
    }

    .btn-transparent {
        font-size: 1.25rem;
    }

    .raffle-winners {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: rgba(0, 0, 0, .9);

        .raffle-winners__inner {
            position: absolute;
            height: 60vh;
            width: 70vh;
            left: 50%;
            top: 50%;
            transform: translateX(-50%) translateY(-50%);
            background: #fff;
            padding: 3rem;
            overflow: auto;

            @media(max-width: 1200px) {
                padding: 1rem;
                height: 80vh;
                width: 80%;
            }

            @media(max-width: 768px) {
                padding: 1rem;
                height: 80vh;
                width: 90%;
            }

            .raffle-winners__title {
                font-size: 1.5rem;
            }
        }
    }

    .campaign-over__images {
        justify-content: flex-end;
        position: relative;
        
        @media(max-width: 768px) {
            justify-content: center;
        }
        &:after {
            content: "";
            background: center / cover url(../images/img_confetti_02.png) no-repeat;
            position: absolute;
            left: 90px;
            top: -35px;
            width: 500px;
            height: 245px;

            @media(max-width: 1200px) {
                left: 50px;
                top: -20px;
                width: 400px;
                height: 194px;
            }

            @media(max-width: 992px) {
                left: 0;
                top: 0px;
                width: 300px;
                height: 144px;
            }

            @media(max-width: 768px) {
                left: 17%;
                top: -45px;
                width: 69%;
                height: 68%;
            }

            @media(max-width: 480px) {
                left: -0;
                top: -45px;
                width: 100%;
                height: 65%;
            }
        }

        .img-plane {
            width: 225px;
            height: 190px;

            @media(max-width: 1200px) {
                width: 200px;
                height: 165px;
            }

            @media(max-width: 560px) {
                width: 130px;
                height: 105px;
            }
        }

        .img-flame {
            width: 215px;
            height: 355px;

            @media(max-width: 1200px) {
                width: 190px;
                height: 310px;
            }

            @media(max-width: 560px) {
                width: 135px;
                height: 220px;
            }
        }

        @media(max-width: 768px) {
            order: 1;
        }
    }

    @media(min-width:1024px) {
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    @media(max-width: 768px) {
        .campaign-over__info {
            order: 2;
            width: 100%;
        }
    }
}

.section-campaign-over.active-section {
    display: flex;
}