.section-form--winner {
    display: block;

    .form__confetti {
        height: 235px;
        max-width: 680px;
        margin: 0 auto;
        background: center / cover url(../images/img_confetti_01.png) no-repeat;

        @media(max-width: 768px) {
            height: 205px;
            width: 100%;
        }

        @media(max-width: 560px) {
            height: 190px;
        }

        @media(max-width: 440px) {
            height: 150px;
        }

        .img-ticket {
            max-width: 193px;
            height: 158px;

            @media(max-width: 768px) {
                max-width: 125px;
                height: 90px;
            }
        }
    }

    .form__anouncement {
        font-size: 1.5rem;
        max-width: 300px;
        width: 100%;
        margin: 0 auto;
        padding-bottom: 6.25rem;
        padding-top: 4rem;
    }

    .form-group--input {
        label {
            min-width: 8.25rem;

            @media(max-width: 480px) {
                font-size: .8rem;
                min-width: 5.5rem;
            }

            @media(max-width: 360px) {
                font-size: .75rem;
                min-width: 5rem;
            }
        }

        input {
            max-width: 325px;

            @media(max-width: 480px) {
                font-size: 1rem;
                padding: 0.5rem;
                max-width: 11rem;
            }
        }
    }

    a.btn-primary {
        margin: 0 auto;
    }
}